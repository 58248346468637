// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-assistance-js": () => import("./../../../src/pages/assistance.js" /* webpackChunkName: "component---src-pages-assistance-js" */),
  "component---src-pages-blog-article-js": () => import("./../../../src/pages/blog-article.js" /* webpackChunkName: "component---src-pages-blog-article-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-change-language-js": () => import("./../../../src/pages/change-language.js" /* webpackChunkName: "component---src-pages-change-language-js" */),
  "component---src-pages-compare-js": () => import("./../../../src/pages/compare.js" /* webpackChunkName: "component---src-pages-compare-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-el-gr-js": () => import("./../../../src/pages/el_GR.js" /* webpackChunkName: "component---src-pages-el-gr-js" */),
  "component---src-pages-icons-js": () => import("./../../../src/pages/icons.js" /* webpackChunkName: "component---src-pages-icons-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-ru-js": () => import("./../../../src/pages/index-ru.js" /* webpackChunkName: "component---src-pages-index-ru-js" */),
  "component---src-pages-lavatrici-compare-overlay-js": () => import("./../../../src/pages/lavatrici--compare-overlay.js" /* webpackChunkName: "component---src-pages-lavatrici-compare-overlay-js" */),
  "component---src-pages-lavatrici-js": () => import("./../../../src/pages/lavatrici.js" /* webpackChunkName: "component---src-pages-lavatrici-js" */),
  "component---src-pages-lavatrici-sticky-sidebar-js": () => import("./../../../src/pages/lavatrici-sticky-sidebar.js" /* webpackChunkName: "component---src-pages-lavatrici-sticky-sidebar-js" */),
  "component---src-pages-megacontent-js": () => import("./../../../src/pages/megacontent.js" /* webpackChunkName: "component---src-pages-megacontent-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-pdp-compare-overlay-js": () => import("./../../../src/pages/pdp--compare-overlay.js" /* webpackChunkName: "component---src-pages-pdp-compare-overlay-js" */),
  "component---src-pages-pdp-js": () => import("./../../../src/pages/pdp.js" /* webpackChunkName: "component---src-pages-pdp-js" */)
}

