/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './src/app/_styles/_main.scss'

export const onRouteUpdate = () => {
    const event = document.createEvent('Event');
    event.initEvent('cswidget-gatsby-route-change', true, true);
    window.dispatchEvent(event);
}